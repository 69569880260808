import config from '@lumiere/shared/config';
import logger from '@lumiere/shared/services/logger';
import { captureException, captureMessage } from '@sentry/browser';
import { EmbedActionTypes, ESFeatureTypes, } from '@lumiere/shared/types';
import isEmpty from 'lodash/isEmpty';
import { auth } from '../services';
import { fetchData, reqHeaders } from '../utils/api';
import { LUMIERE_EMBED_EVENTS } from '../utils/types';
import ACTION from './ACTION';
import MUTATION from './MUTATION';
import viewerAPI from '@/services/viewerAPI';
async function getAuthToken() {
    return await fetchData('/api/auth/get-auth-token').then(({ data }) => data);
}
const actions = {
    async [ACTION.FETCH_CHANNEL]({ commit, dispatch }, cid) {
        try {
            const { data } = await fetchData(`/api/viewer/getChannel/${cid}`);
            commit(MUTATION.SET_CHANNEL, data);
            void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                event: LUMIERE_EMBED_EVENTS.FETCH_CHANNEL,
                embedActionType: EmbedActionTypes.CHANNEL_VIEWER_FEEDBACK,
            });
        }
        catch (e) {
            captureException(e);
            void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                error: e,
                event: LUMIERE_EMBED_EVENTS.FETCH_CHANNEL,
                embedActionType: EmbedActionTypes.CHANNEL_VIEWER_FEEDBACK,
            });
            if (typeof window !== 'undefined') {
                window.location.href = window.location.origin;
            }
        }
    },
    async [ACTION.FETCH_VIDEO]({ commit, dispatch }, { vid, cid }) {
        try {
            const { data } = await fetchData(`/api/viewer/getVideo?vid=${vid}`);
            commit(MUTATION.SET_VIDEO, data);
            // dispatch action to parent window
            void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                event: LUMIERE_EMBED_EVENTS.FETCH_VIDEO,
            });
        }
        catch (error) {
            captureException(error);
            void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                error,
                event: LUMIERE_EMBED_EVENTS.FETCH_VIDEO,
            });
            // alternative fix https://github.com/zeit/next.js/issues/2177#issuecomment-477739281
            if (typeof window !== 'undefined' && !cid) {
                window.location.href = window.location.origin;
            }
        }
    },
    [ACTION.SUBSCRIBE_TO_AUTH_STATE_CHANGE]({ state, commit }) {
        const timeEnd = logger.time('AUTHENTICATE');
        const tokenPromise = getAuthToken();
        auth.onAuthStateChanged(async (user) => {
            if (!state.user) {
                const registeredUser = await tokenPromise;
                logger.log('onAuthStateChanged', { registeredUser, user });
                if (user) {
                    if (registeredUser && registeredUser.uid !== user.uid) {
                        logger.log('registeredUser.uid !== user.uid, signInWithCustomToken');
                        void auth.signInWithCustomToken(registeredUser.token);
                    }
                    else if (!registeredUser && !user.isAnonymous) {
                        logger.log('signOut');
                        void auth.signOut();
                    }
                    else {
                        logger.log('SET_USER', { user });
                        commit(MUTATION.SET_USER, user);
                        timeEnd();
                    }
                }
                else {
                    if (registeredUser) {
                        logger.log('signInWithCustomToken');
                        void auth.signInWithCustomToken(registeredUser.token);
                    }
                    else {
                        logger.log('signInAnonymously');
                        void auth.signInAnonymously();
                    }
                }
            }
        });
    },
    async [ACTION.CREATE_VIEW_RECORD]({ state, commit, dispatch }, { vid = null, cid = null }) {
        const timeEnd = logger.time('CREATE_VIEW_RECORD');
        const viewRecord = {
            eid: state.queryParams.eid || null,
            asset: {
                video: vid,
                playlist: null,
                channel: cid,
            },
            userEnvironment: state.userEnvironment,
            other: {
                returnURL: state.queryParams.returnUrl || null,
                iFrameEmbed: state.iFrameEmbed,
            },
            source: {
                geo: state.geo_data,
            },
        };
        try {
            const viewPromise = viewerAPI.video.createView({
                partialRecord: viewRecord,
                headers: {
                    'user-agent': navigator.userAgent,
                    'referrer': location.href,
                },
            });
            commit(MUTATION.SET_VIEW_PROMISE, viewPromise);
            const { viewId } = await viewPromise;
            void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                event: LUMIERE_EMBED_EVENTS.CREATE_VIEW_RECORD,
                viewId,
            });
            timeEnd();
        }
        catch (e) {
            captureException(e);
        }
    },
    async [ACTION.POST_DATA]({ state, dispatch }, data) {
        if (state.viewPromise) {
            const { viewId, viewRecord } = await state.viewPromise;
            const timeEnd = logger.time('POST_DATA', data);
            try {
                void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                    event: LUMIERE_EMBED_EVENTS.POST_DATA,
                    data,
                });
                // let response
                const viewPayload = {
                    id: viewId,
                    ...viewRecord,
                };
                const response = await (data.type === ESFeatureTypes.Emoji
                    ? viewerAPI.video.addEmojiViewData({
                        view: viewPayload,
                        data,
                    })
                    : data.type === ESFeatureTypes.Comment
                        ? viewerAPI.video.addCommentViewData({
                            view: viewPayload,
                            data,
                        })
                        : data.type === ESFeatureTypes.Question
                            ? viewerAPI.video.addQuestionViewData({
                                view: viewPayload,
                                data,
                            })
                            : null);
                timeEnd(response);
            }
            catch (e) {
                captureException(e);
            }
        }
    },
    async [ACTION.VIEW_SESSION_ENDED]({ state, dispatch }) {
        const viewProps = await state.viewPromise;
        if (viewProps?.viewId) {
            void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                event: LUMIERE_EMBED_EVENTS.VIEW_SESSION_ENDED,
            });
            const url = `${config.adminAppURL}/api/viewer/viewSessionEnded`;
            let headers = {
                type: 'text/plain; charset=UTF-8',
            };
            let blob = new Blob([viewProps.viewId], headers);
            navigator.sendBeacon(url, blob);
        }
    },
    async [ACTION.GET_TOTAL_EMOJI_CLICKS](_context, { fid, vid }) {
        try {
            const headers = await reqHeaders();
            const { data } = await fetchData(`/api/viewer/total-emoji-clicks`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    fid,
                    vid,
                }),
            });
            const { doc_count, buckets } = data;
            return { doc_count, buckets };
        }
        catch (e) {
            captureException(e);
        }
    },
    async [ACTION.GET_FLY_OUT_EMOJIS](_context, { vid, range }) {
        try {
            const headers = await reqHeaders();
            const { data } = await fetchData(`/api/viewer/flyout-emojis`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    vid,
                    range,
                }),
            });
            return data;
        }
        catch (e) {
            captureException(e);
        }
    },
    async [ACTION.TRACK_VIDEO_ENGAGEMENT]({ state, dispatch }, payload) {
        if (!isEmpty(payload)) {
            void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                event: LUMIERE_EMBED_EVENTS.TRACK_VIDEO_ENGAGEMENT,
                payload,
            });
            if (state.viewPromise) {
                const { viewId, viewRecord } = await state.viewPromise;
                try {
                    await viewerAPI.video.addViewedMoments({
                        view: {
                            id: viewId,
                            ...viewRecord,
                        },
                        data: {
                            moments: payload,
                        },
                    });
                }
                catch (e) {
                    captureMessage('Failed to capture engagement');
                    captureException(e);
                    void dispatch(ACTION.NOTIFY_PARENT_WINDOW, {
                        event: LUMIERE_EMBED_EVENTS.TRACK_VIDEO_ENGAGEMENT,
                        error: e,
                    });
                }
            }
        }
    },
    // Parent windows, if any, can subscribe to this post message
    async [ACTION.NOTIFY_PARENT_WINDOW]({ state }, data = {}) {
        if (typeof window !== 'undefined') {
            if (window.parent && state.iFrameEmbed) {
                const viewProps = await state.viewPromise;
                const payload = data.error
                    ? { error: data.error }
                    : {
                        viewId: viewProps?.viewId,
                        asset: viewProps?.viewRecord?.asset,
                        eid: viewProps?.viewRecord?.eid,
                        returnURL: viewProps?.viewRecord?.other?.returnURL,
                        userEnvironment: viewProps?.viewRecord?.userEnvironment,
                        video: {
                            title: state.video?.video.title,
                            duration: state.video?.video.duration,
                            description: state.video?.video.description,
                            aspectRatio: state.video?.video.aspectRatio,
                        },
                        channel: !state.channel
                            ? undefined
                            : {
                                title: state.channel.channel.title,
                                description: state.channel.channel.description,
                                color: state.channel.channel.color,
                                options: state.channel.channel.options,
                            },
                        ...data,
                    };
                let embedActionType;
                if (data.embedActionType) {
                    embedActionType = data.embedActionType;
                    delete payload.embedActionType;
                }
                else {
                    embedActionType = viewProps?.viewRecord?.asset.channel
                        ? EmbedActionTypes.CHANNEL_VIEWER_FEEDBACK
                        : EmbedActionTypes.VIEWER_FEEDBACK;
                }
                window.parent.postMessage({ type: embedActionType, ...payload }, document.referrer);
            }
        }
    },
};
export default actions;
