import Vue from 'vue';
import MUTATION from '@/store/MUTATION';
export default Vue.extend({
    name: 'HandleIframe',
    mounted() {
        this.handleIFrame();
    },
    methods: {
        handleIFrame() {
            if (typeof window !== 'undefined') {
                // whether it's an IFrame embed
                if (window.location !== window.parent.location) {
                    this.$store.commit(MUTATION.SET_IFRAME_EMBED_DATA, {
                        parentURL: document.referrer,
                        params: this.$route.query,
                    });
                }
            }
        },
    },
});
