export var LUMIERE_EMBED_EVENTS;
(function (LUMIERE_EMBED_EVENTS) {
    LUMIERE_EMBED_EVENTS["FETCH_VIDEO"] = "FETCH_VIDEO";
    LUMIERE_EMBED_EVENTS["FETCH_CHANNEL"] = "FETCH_CHANNEL";
    LUMIERE_EMBED_EVENTS["CREATE_VIEW_RECORD"] = "CREATE_VIEW_RECORD";
    LUMIERE_EMBED_EVENTS["POST_DATA"] = "POST_DATA";
    LUMIERE_EMBED_EVENTS["VIEW_SESSION_ENDED"] = "VIEW_SESSION_ENDED";
    LUMIERE_EMBED_EVENTS["TRACK_VIDEO_ENGAGEMENT"] = "TRACK_VIDEO_ENGAGEMENT";
    LUMIERE_EMBED_EVENTS["CHANNEL_WINDOW_DIMENSION"] = "CHANNEL_WINDOW_DIMENSION";
    LUMIERE_EMBED_EVENTS["CHANNEL_VIDEO_LOADED"] = "CHANNEL_VIDEO_LOADED";
    LUMIERE_EMBED_EVENTS["CHANNEL_VIDEO_UNLOADED"] = "CHANNEL_VIDEO_UNLOADED";
    LUMIERE_EMBED_EVENTS["FILTER_BY_TAG"] = "FILTER_BY_TAG";
    LUMIERE_EMBED_EVENTS["FILTER_BY_SEARCH"] = "FILTER_BY_SEARCH";
    LUMIERE_EMBED_EVENTS["VIDEO_PLAYER_MOUNTED"] = "VIDEO_PLAYER_MOUNTED";
    LUMIERE_EMBED_EVENTS["VIDEO_PLAYER_RENDERED"] = "VIDEO_PLAYER_RENDERED";
    LUMIERE_EMBED_EVENTS["CHANNEL_EMBED_MOUNTED"] = "CHANNEL_EMBED_MOUNTED";
    LUMIERE_EMBED_EVENTS["CHANNEL_EMBED_RENDERED"] = "CHANNEL_EMBED_RENDERED";
})(LUMIERE_EMBED_EVENTS || (LUMIERE_EMBED_EVENTS = {}));
