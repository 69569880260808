import { captureException, captureMessage } from '@sentry/browser';
// https://github.com/jonbern/fetch-retry
import FetchRetry from 'fetch-retry';
// https://github.com/lquixada/cross-fetch
import crossFetch from 'cross-fetch';
import { getToken } from '@/services/firebase/auth';
import config from '@lumiere/shared/config';
import logger from '@lumiere/shared/services/logger';
const FetchRetryDefaults = {
    retries: 5,
    retryDelay: (attempt) => (attempt + 1) * 1000,
    retryOn: (_, error, response) => {
        if (error !== null || (response?.status && response.status >= 500)) {
            return true;
        }
        if (response?.status && response.status == 401) {
            logger.error('@fetch-retry: E-401 - user not authenticated', {
                error,
            });
            captureMessage('@fetch-retry: E-401 - user not authenticated');
            captureException(error);
        }
    },
};
export const transformedFetch = (defaults = FetchRetryDefaults) => FetchRetry(crossFetch, defaults);
const baseURL = config.adminAppURL;
async function fetchData(url, options = {}) {
    const fullUrl = `${baseURL}/` + url.replace(/^\//, '');
    const response = await transformedFetch()(fullUrl, {
        mode: 'cors',
        credentials: 'include',
        referrerPolicy: 'origin',
        ...options,
        headers: {
            ...options.headers,
            'x-referer': typeof location !== 'undefined' ? location.href : '-',
            'Content-Type': 'application/json',
        },
    }).catch((e) => {
        captureMessage('@fetch: Error');
        captureException(e);
        throw e;
    });
    const result = await response.text();
    if (!response.ok)
        throw new Error(result);
    try {
        return { data: JSON.parse(result) };
    }
    catch (_e) {
        return { data: result };
    }
}
export { fetchData };
export const reqHeaders = () => getToken().then((token) => ({
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': `Bearer ${token}`,
}), (error) => {
    captureMessage(`Couldn't get idToken for request header`);
    captureException(error);
    return {};
});
