import { Video, Feature, Channel } from '@lumiere/db/types'
import {
  ViewRecord,
  IFrameEmbed,
  UserEnvironment,
  GEO_DATA,
} from '@lumiere/shared/types'
import firebase from 'firebase/app'

export interface ViewerAppState {
  viewPromise: null | Promise<{ viewId: string; viewRecord: ViewRecord }>
  video: null | { video: Video; features: Feature[] }
  playlist: null
  channel: null | { channel: Channel; channelVideos: Video[] }
  user: null | firebase.User
  queryParams: {
    eid?: string
    returnUrl?: string
    time?: number
  }
  iFrameEmbed: null | IFrameEmbed
  userEnvironment: null | UserEnvironment
  geo_data: GEO_DATA
}

const defaultState: () => ViewerAppState = () => ({
  video: null,
  playlist: null,
  channel: null,
  user: null,
  viewPromise: null,
  queryParams: {},
  iFrameEmbed: null,
  userEnvironment: null,
  geo_data: {
    country_iso_code: null,
    region_iso_code: null,
    location: null,
  },
})

export default defaultState
