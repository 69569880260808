import { Video } from '@lumiere/db/types'
import {
  CommentViewData,
  ViewsByDay,
  QuestionViewData,
} from './elasticsearch.view.types'

export enum Emotions {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  INDETERMINATE = 'INDETERMINATE',
}

export interface CommentInsightsData {
  [key: string]: any
  _id: string
  _source: Partial<CommentViewData>
}

export interface CommentInsightsDataWithAggs {
  hits: {
    total: {
      value: number
    }
    hits: CommentInsightsData[]
  }
  aggregations: CommentInsightAggs
}

export type CommentInsightAggs = {
  [key: string]: any
  comment_count: {
    buckets: { [key: string]: any } | any[]
  }
  sentiment_count: {
    buckets: {
      [Emotions.POSITIVE]: RangedKeyBucketItem
      [Emotions.NEGATIVE]: RangedKeyBucketItem
      [Emotions.NEUTRAL]: RangedKeyBucketItem
    }
  }
  average_rating: {
    value: number
  }
}

export type RangedKeyBucketItem = {
  doc_count: number
  from?: string
  to?: string
}

export interface EmojiInsightEmojiBucket {
  key: string
  doc_count: number
  particular_emoji_buckets: {
    buckets: Array<{ key: number; doc_count: number }>
  }
}

export type EmojiInsightsOutputData = Array<EmojiInsightEmojiBucket>
export type InsightsOutputData =
  | EmojiInsightsOutputData
  | CommentInsightsDataWithAggs

export interface InsightsOutputCommentResponse {
  hits: any
  aggregations: any
  timeline_hits: any
}

export interface WorkspaceVideoInsightsResponse {
  /** videoId */
  key: string
  /** number of views */
  doc_count: number
  /** latest time a document was created with asset.video.keyword=key */
  timestamp: {
    value: number
    value_as_string: string
  }
  /** views over last 30 days */
  last_30_days: {
    /** total number of views over 30-day period */
    doc_count: number
    views_by_days: {
      buckets: ViewsByDay[]
    }
  }
  /** flatten the data for the last 30 days */
  last30DaysFlattened: number[]
  /** number of behaviors doc */
  total_behaviors: number
  /** the video doc from firestore */
  video: Video
  /** human readable date string */
  createdOn: string
  /** creator displayName gotten from video.createdBy */
  creatorName: string
}

export interface QuestionInsightsData {
  [key: string]: any
  _id: string
  _source: Partial<QuestionViewData>
}

export type QuestionInsightAggs = {
  question_responses: {
    doc_count: number
    sentiment_count: {
      buckets: {
        [Emotions.POSITIVE]: RangedKeyBucketItem
        [Emotions.NEGATIVE]: RangedKeyBucketItem
        [Emotions.NEUTRAL]: RangedKeyBucketItem
      }
    }
    average_rating: {
      value: number | null
    }
    counts_per_response: {
      doc_count_error_upper_bound: number
      sum_other_doc_count: number
      buckets: {
        key: string
        doc_count: number
      }[]
    }
  }
}
