import { ViewsByDay } from './elasticsearch.view.types'

export const TOP_LEVEL_METRICS = 'TOP_LEVEL_METRICS'

export interface ChannelMetricsResponse {
  total_video_views?: number
  total_viewing_time?: number
  /** unique views (retention) for a video within a channel */
  retentions_per_viewer?: RetentionPerViewer[]
  /** all views (views count) of a video within a channel */
  total_views_by_video?: Record<string, ViewsByVideo>
  /** unique channel views related computation */
  unique_viewers?: number
  views_count?: number
  views_per_day?: ViewsByDay[]

  retentions_per_video?: Record<string, RetentionPerVideo>
}

/** unique views per viewer for channel videos */
export interface RetentionPerViewer {
  /** userId */
  key: string
  /** total views per viewer for channel videos */
  doc_count: number
  /** total_viewing_time per unique viewer for channel videos*/
  total_viewing_time: {
    doc_count: number
  }
}

export interface ViewsByVideo {
  /** video Id */
  key: string
  /** total views */
  doc_count: number
}

export interface RetentionPerVideo {
  /** videoId */
  key: string
  /** retention doc occurences */
  doc_count: number
  /** video viewing (retention) within a channel*/
  viewing_share: number
  total_viewing_time: {
    doc_count: number
  }
}
